import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrestaCard from "../components/card-prestations"
import { Container, CardDeck, Dropdown } from "react-bootstrap"
const prestations = require('../../data/prestations.json')
const typePrestas = require('../../data/type-prestations.json')

export default class Prestations extends Component {
    constructor(props) {
        super(props)
        this.onTypeFilterChange = this.onTypeFilterChange.bind(this);
        this.state = {
            typeFilter: this.props.location.search ? decodeURI(this.props.location.search).slice(1) : "Toute"
        }
    }

    onTypeFilterChange = (e) => {
        this.setState({
            typeFilter: e
        })
    }

    render() {

        const { typeFilter } = this.state
        const selectedPrestations = typeFilter === "Toute" ? prestations : prestations.filter(el => el.type === typeFilter)

        return (
            <Layout>
                <SEO title="Prestations" />
                <Container>
                    <h1 style={{ marginTop: "2rem" }}>Prestations</h1>

                    <p style={{ margin: "2rem 0px 2rem" }}>
                        Ce tableau récapitule les caractéristiques de l'ensemble de mes prestations.
                    <br />
                    Les séjours comprenant des nuitées sont portés par l'agence Pyrénées Trekking car la vente de séjours tout compris nécessite un agréement que je ne possède pas à titre individuel. Les séjours que je vous propose sont toutefois imaginés, contruits, organisés et réalisés par mes soins.
                    <br />
                    Pour des demandes particulières, tel que l'engagement privé ou des souhaits de séjour sur mesure, n'hésitez pas à me contacter !
                    </p>

                    <Dropdown style={{ textAlign: "right" }} onSelect={(e) => this.onTypeFilterChange(e)} >
                        <Dropdown.Toggle variant="primary" id="dropdown">{typeFilter}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {typePrestas.map((el, i) => <Dropdown.Item key={i} eventKey={el} >{el + "s"}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    <CardDeck style={{ marginTop: "2rem" }}>
                        {selectedPrestations.map((el, i) => <PrestaCard key={i} presta={el} />)}
                    </CardDeck>

                </Container>
            </Layout >
        )
    }
}