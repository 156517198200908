import React from "react"
import { Card, Carousel } from "react-bootstrap"
import moment from "moment"
import DetailsRando from "./icons-caracteristique-rando"
import BadgeLevel from "./badge-level"
import 'moment/locale/fr'
moment.locale("fr")


const CardPrestations = ({ presta }) => {

    const toPrestaPage = () => {
        window.location.href = "/prestations/" + presta.titre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(" ", "-")
    }

    return (

        <Card className="clickable-card" onClick={toPrestaPage}>
            <Carousel
                keyboard={false}
                prevIcon={<span />}
                nextIcon={<span />}
                indicators={false}
                fade={true}
                interval={5000}>
                {presta.imgs.slice(0, 3).map((el, i) =>
                    <Carousel.Item key={i}><img style={{ height: "20rem", objectFit: "cover" }} className="d-block w-100" src={'../images/' + el} alt={""} /></Carousel.Item>
                )}
            </Carousel>
            <Card.Body className="pt-1">
                <div className="pt-2">{presta.titre} - <small>{presta.location}</small></div>
                <div className="pt-0 pb-2"><BadgeLevel level={presta.level} isGeneratedPage={true} /></div>
                <div><small><DetailsRando presta={presta} imgWidth={"1.5rem"} spaceImgTxt={"0.5rem"} isGeneratedPage={true} /></small></div>
                <div className="pt-2"><small>{presta.shortdescription}</small></div>
            </Card.Body>
        </Card>

    )
}

export default CardPrestations